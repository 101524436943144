$euiColorPrimary: #1595d3;
$euiColorAccent: #f88e0a; // secondary
$euiColorDanger: #c13930;
$euiColorWarning: #d76e10;
$euiColorSuccess: #00805e;
$euiColorLightShade: #d2d2d2;
$euiBorderRadius: 5px;

@import '@elastic/eui/src/theme_light';
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,400i,700,700i');
@import url('https://rsms.me/inter/inter-ui.css');

.App {
	& > .euiPage {
		min-height: calc(100vh - 50px);
	}

	.euiFieldText,
	.euiFieldPassword,
	.euiFieldNumber,
	.euiTextArea,
	.euiComboBox .euiComboBox__inputWrap {
		border-radius: $euiBorderRadius;
	}

	.euiHeader--dark .euiHeaderSectionItem:after {
		background: #fff;
	}
}

.euiHeaderProfile {
	margin: 0;
	padding: 8px;
}

.euiBody--headerIsFixed {
	padding-top: 59px;
}

.euiHeader--dark {
	background-color: $euiColorPrimary;
	height: 59px;
}

.auth {
	&.form-container {
		width: 300px;
	}

	.euiPageContentHeader {
		justify-content: center;
	}
}

.registration {
	&.form-container {
		width: 800px;
		padding: 0;
	}

	.left-column {
		max-width: 40%;
	}

	.right-column {
		background-color: $euiColorPrimary;
		padding: 20px;
		border-top-right-radius: $euiBorderRadius;
		border-bottom-right-radius: $euiBorderRadius;

		.euiText {
			h3 {
				color: $euiColorEmptyShade;
			}
		}

		.euiFormLabel,
		.euiCheckbox__label {
			color: $euiColorEmptyShade;
		}

		.euiToolTipAnchor {
			display: inline;
		}

		.euiFieldText:focus {
			background-color: white;
			background-image: linear-gradient(
				to top,
				$euiColorAccent,
				$euiColorAccent 2px,
				transparent 2px,
				transparent 100%
			);
			background-size: 100% 100%;
			box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
				0 4px 4px -2px rgba(152, 162, 179, 0.2),
				inset 0 0 0 1px rgba(35, 66, 136, 0.1);
		}

		.euiButton--primary.euiButton--fill {
			background-color: $euiColorAccent;
			border-color: $euiColorAccent;
			color: $euiColorEmptyShade;

			&:disabled {
				opacity: 0.7;
			}
		}

		.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
			background-color: $euiColorAccent;
			border-color: $euiColorAccent;
		}

		.euiLink.euiLink--ghost {
			text-decoration: underline;
		}
	}
}

.document {
	&.form-container {
		width: 900px;
	}
}

.main-menu-button {
	height: 59px;
	width: 59px;
	border-radius: 0;

	&:focus {
		background-color: transparent;
	}
}

.brand-container {
	justify-content: center;
	height: 58px;
	padding-left: 12px;
	padding-right: 12px;

	img {
		height: 30px;
		width: auto;
	}
}

.euiHeaderSectionItem__button {
	height: 59px;
	width: 59px;
}

.euiBody--headerIsFixed .euiCollapsibleNav {
	top: 59px;
    height: calc(100% - 59px);
}

.euiCollapsibleNav {
	.euiCollapsibleNavGroup__heading {
		padding: 12px;
	}

	.euiCollapsibleNavGroup__children:empty {
		display: none;
	}
}

/* disable hiding scrollbar so the ui won't wobble */
.euiBody-hasOverlayMask {
	overflow: auto;
}

@media screen and (min-width: 1000px) {
	.euiFlexGroup .sideBar {
		max-width: 25%;
		.euiDescriptionList.euiDescriptionList--column,
		.euiDescriptionList.euiDescriptionList--responsiveColumn {
			& > * {
				margin-top: 4px;
			}
		}
	}
	
	.euiFlexGroup .rx-sideBar {
		min-width: 40%;
		max-width: 40%;
		.euiDescriptionList.euiDescriptionList--column,
		.euiDescriptionList.euiDescriptionList--responsiveColumn {
			& > * {
				margin-top: 4px;
			}
		}
	}
}

.euiPage.hasSticky {
	padding-top: 0;
	.euiPageHeader.sticky {
		position: -webkit-sticky;
		position: sticky;
		top: 59px;
		margin-left: -16px;
		margin-right: -16px;
		margin-bottom: 0;
		padding: 16px;
		background-color: #fafbfd;
		z-index: 1000;
	}
}

.euiBody--collapsibleNavIsDocked {
	.euiPage.hasSticky {
		padding-top: 0;
		.euiPageHeader.sticky {
			margin-left: -15px;
		}
	}
}

.euiSuperSelectControl {
	border-radius: $euiBorderRadius;
}

.euiBody-hasOverlayMask .euiOverlayMask > div {
	padding-top: 70px;
}

.euiButton--primary.euiButton--fill {
	color: $euiColorEmptyShade;
}

.euiTitle {
	.subtitle {
		font-style: italic;
		font-weight: 400;
		color: $euiTextSubduedColor;
	}
}

.validation-form {
	& > .euiFlexGroup {
		& > .euiFlexItem {
			max-width: calc(50% - 24px);
		}
	}
}

.euiFlyout {
	z-index: 1;
}

.print {
	display: none !important;
}

@media print {
	#root {
		display: none;
	}

	.print {
		display: block !important;
		z-index: 1000;
		min-height: 100vh;
		width:  100%;
		background-color: #fff;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 20px;

		.euiDescriptionList.euiDescriptionList--column > *,
		.euiDescriptionList.euiDescriptionList--responsiveColumn > * {
			margin-top: 5px;
		}
	}
}
